import { bem } from '@lib/bem';
import ItemsTicker from '@ui/ItemsTickerNew';

import Typography from '@ui/Typography';
import PropTypes from 'prop-types';

import './BWTicker.scss';

const {
  block,
  element,
} = bem('bw-ticker');

const BWTicker = ({ tickerItems }) => {
  const itemComponents = tickerItems.map((item, index) => {
    return (
      <div key={index} className="flex items-center">
        <Typography variant="body2" transform="uppercase" color="white" {...element('element')}>
          {item}
        </Typography>
        <div {...element('divider', {}, 'bg-primary400')} />
      </div>
    );
  });

  return (
    <div {...block()}>
      <ItemsTicker
        tickerProps={{ height: 48, autoFill: true }}
        items={itemComponents}
      />
    </div>
  );
};

BWTicker.propTypes = {
  tickerItems: PropTypes.array.isRequired,
};

export default BWTicker;
